var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"fill-height",attrs:{"tile":"","elevation":"1"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{staticClass:"pa-0",attrs:{"elevation":"0"}},[_c('v-toolbar-title',[_vm._v(" Стан розрахунків та перелік документів ")])],1)],1),_c('v-tabs',{staticClass:"custom-tabs",attrs:{"color":"success"}},[_c('v-tab',[_vm._v("Стан розрахунку")]),_c('v-tab',[_vm._v("Акти вик.робіт")]),_c('v-tab-item',[_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"mt-2 mb-7 d-flex"},[_c('date-component',{attrs:{"label":"Період з: ","req":"","class_":_vm.date_from ? 'mr-2' : 'req-star mr-2'},model:{value:(_vm.date_from),callback:function ($$v) {_vm.date_from=$$v},expression:"date_from"}}),_c('date-component',{attrs:{"label":"Період по: ","req":"","class_":_vm.date_to ? 'mr-2' : 'req-star mr-2'},model:{value:(_vm.date_to),callback:function ($$v) {_vm.date_to=$$v},expression:"date_to"}}),_c('v-btn',{attrs:{"depressed":"","color":"success","large":"","height":"48"},on:{"click":function($event){$event.stopPropagation();return _vm.generateInOut.apply(null, arguments)}}},[_vm._v(" Сформувати ")])],1),_c('div',{staticClass:"table",class:{
            'sm-table': _vm.$vuetify.breakpoint.sm,
            'xs-table': _vm.$vuetify.breakpoint.xs,
          },staticStyle:{"min-height":"72vh"}},[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-col"},[_vm._v("Період")]),_c('div',{staticClass:"table-col",staticStyle:{"line-height":"14px"}},[_vm._v("Сальдо "),_c('br'),_vm._v(" на початок")]),_c('div',{staticClass:"table-col"},[_vm._v("Нараховано")]),_c('div',{staticClass:"table-col"},[_vm._v("Оплата")]),_c('div',{staticClass:"table-col",staticStyle:{"line-height":"14px"}},[_vm._v("Сальдо "),_c('br'),_vm._v(" на кінець")]),_c('div',{staticClass:"table-col hidden"})])]),_c('div',{staticClass:"wrapper",staticStyle:{"max-height":"calc(100vh - 230px)","overflow-y":"overlay"},attrs:{"id":"wrapper-table-archive"}},_vm._l((_vm.in_out_items),function(month_row,index){return _c('div',{key:`mr-${index}`,staticClass:"exp-row",class:{
              'expanded-active': _vm.open.includes(`exp-row-${index}`),
            },attrs:{"id":`exp-row-${index}`}},[_c('div',{staticClass:"table-row expand-row",class:{
              'active': _vm.open.includes(`exp-row-${index}`),
            }},[_c('div',{staticClass:"table-col"},[_c('div',[_c('v-icon',{staticClass:"mr-3 expand-icon",attrs:{"size":"22","color":"grey darken-3"},on:{"click":function($event){return _vm.expand_row(`exp-row-${index}`)}}},[_vm._v(" "+_vm._s(_vm.open.includes(`exp-row-${index}`) ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1),_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(month_row.period,'MMMM YYYY'))+" ")])]),_c('div',{staticClass:"table-col",class:[
                        {'error--text': month_row.start_balance > 0},
                        {'success--text': month_row.start_balance < 0}
                    ]},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("formatToFixed")(month_row.balance_start)))+" ")]),_c('div',{staticClass:"table-col",staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("formatToFixed")(month_row.charge)))+" ")]),_c('div',{staticClass:"table-col",staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("formatToFixed")(month_row.pay)))+" ")]),_c('div',{staticClass:"table-col",class:[
                        {'error--text': month_row.end_balance > 0},
                        {'success--text': month_row.end_balance < 0}
                    ]},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("formatToFixed")(month_row.balance_end)))+" ")]),_c('div',{staticClass:"table-col hidden"})]),_c('div',{staticClass:"table-body"},_vm._l((month_row.items),function(detail_row,index){return _c('div',{key:`detail-${index}`,staticClass:"table-row"},[_c('div',{staticClass:"table-col",staticStyle:{"flex":"0 1 620px","padding-left":"56px !important"}},[_vm._v(_vm._s(detail_row.document))]),_c('div',{staticClass:"table-col",staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("formatToFixed")(detail_row.charge)))+" ")]),_c('div',{staticClass:"table-col",staticStyle:{"cursor":"pointer"}},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("formatToFixed")(detail_row.pay)))+" ")]),_c('div',{staticClass:"table-col",class:[
                        {'error--text': detail_row.end_balance > 0},
                        {'success--text': detail_row.end_balance < 0}
                    ]}),_c('div',{staticClass:"table-col hidden"})])}),0)])}),0)])])]),_c('v-tab-item',[_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"mt-2 mb-7 d-flex"},[_c('date-component',{attrs:{"label":"Період з: ","req":"","class_":_vm.date_from_out ? 'mr-2' : 'req-star mr-2'},model:{value:(_vm.date_from_out),callback:function ($$v) {_vm.date_from_out=$$v},expression:"date_from_out"}}),_c('date-component',{attrs:{"label":"Період по: ","req":"","class_":_vm.date_to_out ? 'mr-2' : 'req-star mr-2'},model:{value:(_vm.date_to_out),callback:function ($$v) {_vm.date_to_out=$$v},expression:"date_to_out"}}),_c('v-btn',{attrs:{"depressed":"","color":"success","large":"","height":"48"},on:{"click":function($event){$event.stopPropagation();return _vm.generateDocumentList.apply(null, arguments)}}},[_vm._v(" Сформувати ")])],1),_c('v-data-table',{staticClass:"document-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.items},scopedSlots:_vm._u([{key:"item.icon",fn:function({ item }){return [_c('v-icon',{attrs:{"color":"teal"},on:{"click":function($event){$event.stopPropagation();return _vm.download_document(item)}}},[_vm._v(" mdi-download ")])]}},{key:"item.date",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date,'DD.MM.YYYY'))+" ")])]}},{key:"item.sum",fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm._f("formatToFixed")(item.sum)))+" ")])]}}])})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }