<template>
  <v-card tile elevation="1" class="fill-height">
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title>
          Стан розрахунків та перелік документів
        </v-toolbar-title>
      </v-toolbar>
    </v-card-title>

    <v-tabs  class="custom-tabs" color="success">
      <v-tab>Стан розрахунку</v-tab>
      <v-tab>Акти вик.робіт</v-tab>

      <v-tab-item>
        <div class="px-3">
          <div class="mt-2 mb-7 d-flex">
            <date-component v-model="date_from" label="Період з: " req :class_="date_from ? 'mr-2' : 'req-star mr-2'"></date-component>
            <date-component v-model="date_to" label="Період по: " req :class_="date_to ? 'mr-2' : 'req-star mr-2'"></date-component>
            <v-btn depressed color="success" large height="48" @click.stop="generateInOut">
              Сформувати
            </v-btn>
          </div>
          <div class="table" style="min-height: 72vh"
               :class="{
              'sm-table': $vuetify.breakpoint.sm,
              'xs-table': $vuetify.breakpoint.xs,
            }"
          >
            <div class="table-header">
              <div class="table-row">
                <div class="table-col">Період</div>
                <div class="table-col" style="line-height: 14px">Сальдо <br> на початок</div>
                <div class="table-col">Нараховано</div>
                <div class="table-col">Оплата</div>
                <div class="table-col" style="line-height: 14px">Сальдо <br> на кінець</div>
                <div class="table-col hidden"></div>
              </div>
            </div>
            <div class="wrapper" style="max-height: calc(100vh - 230px); overflow-y: overlay" id="wrapper-table-archive">
              <div v-for="(month_row, index) in in_out_items" :key="`mr-${index}`" class="exp-row" :id="`exp-row-${index}`"
                   :class="{
                'expanded-active': open.includes(`exp-row-${index}`),
              }"
              >
                <div class="table-row expand-row" :class="{
                'active': open.includes(`exp-row-${index}`),
              }">
                  <div class="table-col">
                    <div>
                      <v-icon size="22" color="grey darken-3" class="mr-3 expand-icon" @click="expand_row(`exp-row-${index}`)">
                        {{ open.includes(`exp-row-${index}`) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
                      </v-icon>
                    </div>
                    <div class="text-capitalize">
                      {{ month_row.period | formatDate('MMMM YYYY') }}
                    </div>
                  </div>
                  <div class="table-col"
                       :class="[
                          {'error--text': month_row.start_balance > 0},
                          {'success--text': month_row.start_balance < 0}
                      ]"
                  >
                    {{ month_row.balance_start | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col" style="cursor: pointer">
                    {{ month_row.charge | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col" style="cursor: pointer">
                    {{ month_row.pay | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col"
                       :class="[
                          {'error--text': month_row.end_balance > 0},
                          {'success--text': month_row.end_balance < 0}
                      ]"
                  >
                    {{ month_row.balance_end | formatToFixed | formatNumber }}
                  </div>
                  <div class="table-col hidden"></div>
                </div>
                <div class="table-body">
                  <div v-for="(detail_row, index) in month_row.items" :key="`detail-${index}`" class="table-row">
                    <div class="table-col" style="flex: 0 1 620px; padding-left: 56px !important;">{{ detail_row.document }}</div>
                    <div class="table-col" style="cursor: pointer">
                      {{ detail_row.charge | formatToFixed | formatNumber }}
                    </div>
                    <div class="table-col" style="cursor: pointer">
                      {{ detail_row.pay | formatToFixed | formatNumber }}
                    </div>
                    <div class="table-col"
                         :class="[
                          {'error--text': detail_row.end_balance > 0},
                          {'success--text': detail_row.end_balance < 0}
                      ]"
                    >
                    </div>
                    <div class="table-col hidden"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-tab-item>
      <v-tab-item>
        <div class="px-3">
          <div class="mt-2 mb-7 d-flex">
            <date-component v-model="date_from_out" label="Період з: " req :class_="date_from_out ? 'mr-2' : 'req-star mr-2'"></date-component>
            <date-component v-model="date_to_out" label="Період по: " req :class_="date_to_out ? 'mr-2' : 'req-star mr-2'"></date-component>
            <v-btn depressed color="success" large height="48" @click.stop="generateDocumentList">
              Сформувати
            </v-btn>
          </div>
          <v-data-table
              :headers="tableHeaders"
              :items="items"
              class="document-table"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon color="teal"
                      @click.stop="download_document(item)"
              >
                mdi-download
              </v-icon>
            </template>
            <template v-slot:item.date="{ item }">
            <span>
              {{ item.date | formatDate('DD.MM.YYYY') }}
            </span>
            </template>
            <template v-slot:item.sum="{ item }">
              <span class="font-weight-bold">
                {{ item.sum | formatToFixed | formatNumber }}
              </span>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import soapAPI from "@/utils/axios/soap"
import {addMothsToStringDate, getCurrentDate, beginOfMonth} from "@/utils/icons"
import {formatDate} from "@/filters";
import {ALERT_SHOW} from "@/store/actions/alert";

export default {
  name: "BalanceDetail",
  data() {
    return {
      items: [],
      in_out_items: [],
      date_from: '',
      date_from_out: '',
      date_to: '',
      date_to_out: '',
      open: [],
      tableHeaders: [
        {text: '', value: 'icon', width: 20},
        {text: 'Посилання', value: 'represent'},
        {text: 'Дата', value: 'date'},
        {text: 'Номер', value: 'number'},
        {text: 'Сума', value: 'sum'},
      ],
    }
  },
  methods: {
    expand_row: function (row_id) {
      const row = document.getElementById(row_id)
      const prev_row_index = +row_id.substring(row_id.lastIndexOf('-')+1)
      const table_body = row.getElementsByClassName('table-body')[0]
      table_body.classList.toggle('show')
      if (this.open.includes(row_id)) {
        const open_id = this.open.indexOf(row_id)
        this.open.splice(open_id, 1)
        if (prev_row_index >= 0) {
          let prev_row = document.getElementById(`exp-row-${prev_row_index}`)
          prev_row.scrollIntoView({block: "start", behavior: "smooth"})
        }
      } else {
        this.open.push(row_id)
        row.scrollIntoView({block: "start", behavior: "smooth"})
        // window.scrollTop(row.offset().top - row.offset().top/5);
      }
    },
    download_document(item) {
      soapAPI.download_document({
        document_date: item.date,
        document_number: item.number,
      })
          .then(response => {
            if (response.data.type === 'application/json') {
              this.$store.dispatch(ALERT_SHOW, {text: "Виникла помилка при завантажені документа...", color: 'error lighten-1'})
              return
            }
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a');
            const file_name = `act_${formatDate(item.date, 'MM_YYYY')}_${item.number}.pdf`
            link.href = url;
            link.setAttribute('download', file_name); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },
    generateInOut() {
      if (!this.date_from_out || !this.date_to_out) {
        this.$store.commit(ALERT_SHOW, { text: 'Заповніть період з та період по', color: 'error' })
        return
      }
      soapAPI.get_in_out({
        date_from: this.date_from_out,
        date_to: this.date_to_out
      })
          .then(response => response.data)
          .then(data => {
            this.in_out_items = data
          })
    },
    generateDocumentList() {
      if (!this.date_from || !this.date_to) {
        this.$store.commit(ALERT_SHOW, { text: 'Заповніть період з та період по', color: 'error' })
        return
      }
      soapAPI.get_documents({
        date_from: this.date_from,
        date_to: this.date_to
      })
          .then(response => response.data)
          .then(data => {
            this.items = data
          })
    }
  },
  created() {
    const date_to = getCurrentDate()
    const date_from = addMothsToStringDate(beginOfMonth(date_to), -12)
    this.date_from = date_from
    this.date_to = date_to
    this.date_from_out = date_from
    this.date_to_out = date_to
    soapAPI.get_documents({
      date_from: date_from,
      date_to: date_to
    })
    .then(response => response.data)
    .then(data => {
      this.items = data
    })

    soapAPI.get_in_out({
          date_from: date_from,
          date_to: date_to
        })
        .then(response => response.data)
        .then(data => {
          this.in_out_items = data
        })
  }
}
</script>

<style scoped lang="scss">
$border-color: #e6e6e6;
$border-color-mini: #d9d9d9;

td {
  height: 36px !important;
}

:deep(.document-table) {
  td {
    height: 34px !important;
  }
}

.close {
  position: absolute;
  right: -8px;
  top: -9px;
}

:deep(.v-input__control) {
  height: 30px !important;
}

:deep(.v-text-field.v-text-field--solo .v-input__control) {
  min-height: 34px;
}

.buttons {
  :deep(.v-toolbar__content) {
    padding-left: 0;
    padding-right: 0;
  }
}

.expand-icon:focus:after {
  opacity: 0 !important;
}
.expand-icon:hover {
  color: green !important;
}

.table {
  min-width: 1000px;
  .table-row {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    flex: 1 1;
    //border-bottom: 1px solid $border-color;

    .hidden {
      visibility: hidden;
    }

    .table-col {
      flex: 0 1 120px;
      flex-wrap: nowrap;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .table-col:nth-child(1) {
      flex: 0 1 500px;
      min-width: 500px;
      justify-content: flex-start !important;
    }

    .table-col {
      padding: 0 !important;
      .table-col-group {
        width: 100%;
        .table-col {
          border: none !important;
          justify-content: center !important;
        }
      }
    }
  }

  .table-header {
    .table-row {
      font-size: 12px;
      font-weight: bold;
      .table-col {
        text-align: center;
        border: none;
        background-color: #f5f5f5;
        border-bottom: 1px solid $border-color;

        &:nth-child(1) {
          padding: 7px 7px 7px 21px !important;
        }
      }
    }
  }

  .table-body.show {
    display: block;
    max-height: 100%;
    transition: all .3s linear;

    .table-row {
      &:hover .table-col {
        background-color: #f8f8f8;
      }

      .table-col {
        background-color: #f8f8f833;
        border-right: .5px solid $border-color;
        transition: 20ms background-color linear;
        font-size: .8rem !important;
      }

      &:last-child {
        .table-col {
          border-bottom: .5px solid $border-color;
        }
      }
    }
  }

  .table-body {
    display: none;
    max-height: 0;
    .table-row {
      font-size: 0.85rem;


      .table-col:nth-child(1) {
        padding: 7px !important;
        line-height: 16px;
      }

      .table-col:nth-child(1) .icon {
        margin-left: 34px;
      }
    }
  }

  .expand-row {
    font-size: 13px;
    font-weight: normal;
    border-bottom: none !important;
    color: #5a5a5a;

    &:hover .table-col {
      background-color: #f8f8f8;
    }

    .table-col {
      border-right: .5px solid $border-color;
      border-bottom: .5px solid $border-color;
      //border-top: .5px solid $border-color;
      padding: 3px !important;

      &:nth-child(1) {
        padding: 3px 3px 3px 21px !important;
      }
    }

    &:nth-child(1) .table-col {

    }
    &:last-child .table-col {
      border-top: .5px solid $border-color;
    }
  }

  .expand-row.active {
    font-weight: 500;
  }

}

.sm-table {
  min-width: auto !important;

  .table-header {
    display: none;
  }
  .wrapper {
    transition: all ease-in-out .5s;
    .exp-row {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 2px solid lightgray;
      .expand-row {
        flex-wrap: wrap;
        .table-col {
          height: 30px;
          text-align: right;
          justify-content: flex-start;

          &::before {
            display: block;
            font-size: .75rem;
            width: 120px;
            margin-right: 20px;
            color: #2d2d2d;
            font-weight: 500;
          }

          &:nth-child(1) {
            flex: 1 1 100%;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }
          &:nth-child(4) {
            flex: 1 1 100%;
            &::before {
              content: 'Сальдо на поч.: ';
            }
          }
          &:nth-child(5) {
            flex: 1 1 50%;
            &::before {
              content: 'Нараховано: ';
            }
          }
          &:nth-child(6) {
            flex: 1 1 50%;
            &::before {
              content: 'Перерахунок: ';
            }
          }
          &:nth-child(7) {
            flex: 1 1 50%;
            &::before {
              content: 'Пільга: ';
            }
          }
          &:nth-child(8) {
            flex: 1 1 50%;
            &::before {
              content: 'Оплачено: ';
            }
          }
          &:nth-child(9) {
            flex: 1 1 50%;
            &::before {
              content: 'Субсидія: ';
            }
          }
          &:nth-child(10) {
            flex: 1 1 50%;
            &::before {
              content: 'Сальдо на кін.: ';
            }
          }
          &:nth-child(11) {
            display: none;
          }
        }
      }
      .table-body {
        .table-row {
          flex-wrap: wrap !important;
          .table-col {
            height: 30px;
            text-align: right;

            &::before {
              display: block;
              font-size: .75rem;
              width: 120px;
              margin-right: 20px;
              color: #2d2d2d;
              font-weight: 500;
            }

            &:nth-child(1) {
              flex: 1 1 100%;
              border-bottom: .5px solid $border-color;
            }
            &:nth-child(2) {
              flex: 1 1 50%;
              justify-content: flex-start;

              &::before {
                content: 'Об’єм: ';
              }
            }
            &:nth-child(3) {
              flex: 1 1 50%;
              justify-content: flex-start;

              &::before {
                content: 'Тариф: ';
              }
            }
            &:nth-child(4) {
              flex: 1 1 50%;
              justify-content: flex-start;

              &::before {
                content: 'Сальдо поч.: ';
              }
            }
            &:nth-child(5) {
              flex: 1 1 50%;
              justify-content: flex-start;

              &::before {
                content: 'Нараховано: ';
              }
            }
            &:nth-child(6) {
              flex: 1 1 50%;
              justify-content: flex-start;

              &::before {
                content: 'Перерахунок: ';
              }
            }
            &:nth-child(7) {
              flex: 1 1 50%;
              justify-content: flex-start;

              &::before {
                content: 'Пільга: ';
              }
            }
            &:nth-child(8) {
              flex: 1 1 50%;
              justify-content: flex-start;

              &::before {
                content: 'Оплачено: ';
              }
            }
            &:nth-child(9) {
              flex: 1 1 50%;
              justify-content: flex-start;

              &::before {
                content: 'Субсидія: ';
              }
            }
            &:nth-child(10) {
              flex: 1 1 100%;
              border-bottom: .5px solid $border-color;
              border-top: .5px solid $border-color;
              justify-content: flex-start;

              &::before {
                content: 'Сальдо кін.: ';
              }
            }
            &:nth-child(11) {
              display: none;
            }
          }
          &:last-child .table-col {
            border-bottom: none !important;
          }
        }
      }
    }
  }
  .expanded-active {
    margin: 10px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
}

.xs-table {
  min-width: auto !important;

  .table-header {
    display: none;
  }
  .wrapper {
    transition: all ease-in-out .5s;
    .exp-row {
      padding-bottom: 10px;
      margin-bottom: 10px;
      border-bottom: 2px solid lightgray;
      .expand-row {
        flex-wrap: wrap;
        .table-col {
          height: 30px;
          text-align: right;
          justify-content: flex-start;
          flex: 1 1 100%;

          &::before {
            display: block;
            font-size: .75rem;
            width: 50%;
            margin-right: 20px;
            text-align: left;
            padding-left: 20px;
            color: #2d2d2d;
            font-weight: 500;
          }
          &:nth-child(1) {
            padding-left: 6px !important;
            .icon {
              margin-left: 2px !important;
            }
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            display: none;
          }
          &:nth-child(4) {
            &::before {
              content: 'Сальдо на поч.: ';
            }
          }
          &:nth-child(5) {
            &::before {
              content: 'Нараховано: ';
            }
          }
          &:nth-child(6) {
            &::before {
              content: 'Перерахунок: ';
            }
          }
          &:nth-child(7) {
            &::before {
              content: 'Пільга: ';
            }
          }
          &:nth-child(8) {
            &::before {
              content: 'Оплачено: ';
            }
          }
          &:nth-child(9) {
            &::before {
              content: 'Субсидія: ';
            }
          }
          &:nth-child(10) {
            &::before {
              content: 'Сальдо на кін.: ';
            }
          }
          &:nth-child(11) {
            display: none;
          }
        }
      }
      .table-body {
        .table-row {
          flex-wrap: wrap !important;
          .table-col {
            height: 30px;
            text-align: right;
            flex: 1 1 100%;
            justify-content: flex-start;

            &::before {
              display: block;
              font-size: .75rem;
              width: 80%;
              margin-right: 20px;
              color: #2d2d2d;
              font-weight: 500;
            }

            &:nth-child(1) {
              border-bottom: .5px solid $border-color;
              padding-right: 10px !important;
              height: 40px;
              .icon {
                margin-left: 6px !important;

                i {
                  margin-right: 20px !important;
                }
              }
            }
            &:nth-child(2) {
              &::before {
                content: 'Об’єм: ';
              }
            }
            &:nth-child(3) {
              &::before {
                content: 'Тариф: ';
              }
            }
            &:nth-child(4) {
              &::before {
                content: 'Сальдо поч.: ';
              }
            }
            &:nth-child(5) {
              &::before {
                content: 'Нараховано: ';
              }
            }
            &:nth-child(6) {
              &::before {
                content: 'Перерахунок: ';
              }
            }
            &:nth-child(7) {
              &::before {
                content: 'Пільга: ';
              }
            }
            &:nth-child(8) {
              &::before {
                content: 'Оплачено: ';
              }
            }
            &:nth-child(9) {
              &::before {
                content: 'Субсидія: ';
              }
            }
            &:nth-child(10) {
              border-bottom: .5px solid $border-color;
              &::before {
                content: 'Сальдо кін.: ';
              }
            }
            &:nth-child(11) {
              display: none;
            }
          }
          &:last-child .table-col {
            border-bottom: none !important;
          }
        }
      }
    }
  }
  .expanded-active {
    margin: 10px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  }
}
</style>